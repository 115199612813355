<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden.table--cell-overflow-visible
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'base.name' | translate }}
          th.table__cell
            days-count-toggler(v-model="days")
          th.table__cell(v-html="$t('pages.storage.remainder_day')")
          th.table__cell(v-html="$t('pages.storage.remainder_storage')")
          th.table__cell {{ 'base.cost' | translate }}
      v-table-body(
        :cols="5"
        :loading="loading"
        :empty="!ingredients.length")
        ingredients-table-row(
          v-for="ingredient in ingredients"
          :days="days"
          :key="ingredient.id"
          :ingredient="ingredient")
      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="5")
            v-paginate(
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageChangeHandler")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'
import IngredientsTableRow from './IngredientsTableRow'
import DaysCountToggler from './util/DaysCountToggler'

export default {
  name: 'IngredientsTable',

  mixins: [paginationMixin],

  components: {
    IngredientsTableRow,
    DaysCountToggler
  },

  mounted () {
    this.setupPagination(this.fetchIngredients)
  },

  data: () => ({
    days: 'today'
  }),

  methods: {
    ...mapActions(['fetchIngredients', 'applyFilterValue'])
  },

  computed: {
    ...mapGetters([
      'ingredients',
      'ingredientsFilters'
    ]),

    filters () {
      return this.ingredientsFilters
    }
  },

  watch: {
    filters () {
      this.page = 1
      this.loadData()
    },

    days (value) {
      this.applyFilterValue({ key: 'per_day_value', value })
      this.page = this.$route.query.page ?? 1
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
